// 店铺头部事件
import { mapState,mapActions } from "vuex";
import native from "@/utils/native.js";
import { HANDLE_BACK, HANDLE_MESSAGE, HANDLE_SHARE_SELLER } from "@/mixin/mixin_file.js";
export default {
  computed: {
    ...mapState({
      sellerInfo: (state) => state.seller.sellerInfo,
    }),
  },
  methods: {
    ...mapActions({
      ifFocusShop: "seller/ifFocusShop",
    }),
    // 关注店铺
    follow() {
      this.ifFocusShop({
        shopId:this.$route.query.shopId,
        status:'1',//状态 1：关注 2：取消关注
    })
      console.log("关注店铺");
      location. reload()
  this.$router.go(0)
    },
    // 取消关注
    cancle() {
      console.log("取消关注");
      this.ifFocusShop({
        shopId:this.$route.query.shopId,
        status:'2',//状态 1：关注 2：取消关注
    })
    },
    // js调用app
    leftHandle() {
      const name = this.$route.name;
      if (name) {
        native(HANDLE_BACK, { name: name });
      } else {
        native(HANDLE_BACK, null);
      }
    },
    search() {
      this.$router.push("/search");
    },
    rightHandle({ action }) {
      if (action.event == "share") {
        native(HANDLE_SHARE_SELLER, { shopId: this.$route.query.shopId, seller: this.sellerInfo });
      } else if (action.event === "message") {
        native(HANDLE_MESSAGE, { shopId: this.$route.query.shopId });
      } else if (action.event === "toHome") {
        if (this.$route.path !== "/home") {
          this.$router.replace({
            path: "/",
            query: {
              shopId: this.$route.query.shopId,
            },
          });
        }
      } else {
        this.$router.push("/seller/" + this.$route.query.shopId);
      }
    },
    details() {
      if (this.$route.query.shopId) {
        this.$router.push(`/seller/${this.$route.query.shopId}`);
      }
    },
  },
};
